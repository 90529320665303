import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SimpleHero from "../components/SimpleHero";
import BulletList from "../components/BulletList";
import Contact from "../components/Contact";
import SEO from "../components/SEO";

class Demo extends Component {

  render() {

    const {
      data: { seo, hero, bullet, contact },
    } = this.props

    const seoData = seo.primary;

    const heroData = hero.data;

    let bulletData;
    if (bullet.data.bullet_point_list === null) {
      bulletData = "";
    } else {
      bulletData = bullet.data.bullet_point_list.map((b, i) => {
        return (
          <li className="demo-bullet-item">
            { b.bullet_point_item.text }
          </li>
        )
      });
    }

    const contactData = contact.primary.cta.document.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container demo-page">
          <NavBar />
          <SimpleHero
            title={ heroData.demo_page_title.text }
            teaser={ heroData.demo_page_teaser.text }
            />
          <div className="demo-contact-wrapper">
            <BulletList
              bulletPoint={ bulletData }
              />
            <Contact
              title={ contactData.contact_field_title.text }
              teaser={ contactData.contact_field_teaser.text }
              portalId={ contactData.hubspot_portal_id.text }
              formId={ contactData.hubspot_form_id.text }
            />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Demo

Demo.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      primary: PropTypes.shape({
        seo_title: PropTypes.string.isRequired,
        seo_description: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      data: PropTypes.shape({
        demo_page_title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        demo_page_teaser: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      }),
    }),
    bullet: PropTypes.shape({
      data: PropTypes.shape({
        bullet_point_list: PropTypes.array.isRequired,
      }),
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        cta: PropTypes.shape({
          document: PropTypes.array.isRequired
        }),
      }),
    }),
  }),
}

export const demoQuery = graphql`
  query demoPage {

    seo: prismicDemoPageBodySeoTags {
      primary {
        seo_title
        seo_description
      }
    }

    hero: prismicDemoPage {
      data {
        demo_page_title {
          text
        }
        demo_page_teaser {
          text
        }
      }
    }

    bullet: prismicDemoPage {
      data {
        bullet_point_list {
          bullet_point_item {
            text
          }
        }
      }
    }

    contact: prismicDemoPageBody1Cta {
      primary {
        cta {
          document {
            ... on PrismicCta {
              id
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_portal_id {
                  text
                }
                hubspot_form_id {
                  text
                }
              }
            }
          }
        }
      }
    }
    
  }
`